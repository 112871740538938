<template>
  <p>Please wait...</p>
</template>

<script>
export default {
  methods: {
    redirectToAppStore() {
      const query = this.$route.query;

      if (query.deviceType) {
        let deviceType = query.deviceType;

        if (deviceType == "android") {
          window.location.replace(
            "https://play.google.com/store/apps/details?id=com.moonoi.propertymatchnew&hl=en_IN&gl=US"
          );
        } else if (deviceType == "ios") {
          window.location.replace(
            "https://apps.apple.com/app/PropertyMatch/id1617962228"
          );
        } else {
          //fallback to ios
          window.location.replace(
            "https://apps.apple.com/app/PropertyMatch/id1617962228"
          );
        }
      } else {
        window.location.replace(
          "https://apps.apple.com/app/PropertyMatch/id1617962228"
        );
      }
    },
  },
  mounted() {
    this.redirectToAppStore();
  }
};
</script>
